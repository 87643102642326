import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'sw-ui';

import { formatDate } from '../../../../utils/date';
import { formatPercent } from '../../../../bi/utils/formatPercent';

import { LABELS_TABELS } from '../../../../bi/constants/amo';
import { DEFAULTDATE } from '../../../../constants/time';
import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles/styles.module.css';

const { BUTTON: { THEME: { FLAT } } } = COMPONENTS;

class ContactsTable extends Component {
  static propTypes = {
    contacts: PropTypes.array.isRequired,
    amoCRMService: PropTypes.object.isRequired,
  };

  handleDownloadAmoContacts = id => this.props.amoCRMService.downloadAmoContacts(id);

  renderItemsContacts = (item) => {
    const { id, create_date, done, progress } = item;

    const loadingStatus = done ? LABELS_TABELS.STATUS_DONE : LABELS_TABELS.STATUS_NO_DONE;

    return (
      <div key={ `contacts_${id}` } className={ `${styles.row} ${styles.header}` }>
        <div className={ styles.item }>{ id }</div>
        <div className={ styles.item }>{ formatDate(create_date, DEFAULTDATE) }</div>
        <div className={ styles.item }>{ loadingStatus }</div>
        <div className={ styles.item }>{ formatPercent(progress) }</div>
        <div className={ styles.item }>
          <Button
            className={ styles.button }
            theme={ FLAT }
            onClick={ () => this.handleDownloadAmoContacts(id) }
          >
            { LABELS_TABELS.BUTTON_DOWNLOAD }
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { contacts } = this.props;

    return (
      <div className={ styles.wrap_content }>
        { contacts.map(this.renderItemsContacts) }
      </div>
    );
  }
}

export default ContactsTable;
