export const VALUES = {
  TAG: 'CardPayment',
  CARD_PAYMENT_VALUE: 'card',
  CARD_PAYMENT_LABEL: 'Оплачено картой',
  ACCOUNT_VALUE: 'account',
  ACCOUNT_LABEL: 'Оплачено с аккаунта',
};

/**
 * @typedef Trip
 * @type {object}
 * @property {(string | 'CardPayment')[]} Labels
 */

/**
 * @typedef PaymentFilterType
 * @type {'card' | 'account'}
 */

/**
 * @type {(selectedTypes: PaymentFilterType[], trip: Trip) => boolean}
 */
export const filterTripByPayment = (selectedTypes, trip) => {
  if (selectedTypes.length > 0) {
    return selectedTypes.map((paymentType) => {
      if (paymentType === 'card') return trip.Labels.includes(VALUES.TAG);

      if (paymentType === 'account') return !trip.Labels.includes(VALUES.TAG);

      return false;
    }).some(Boolean);
  }

  return true;
};

/**
 * @typedef PaymentTypeValue
 * @type {object}
 * @property {PaymentFilterType} value
 * @property {string} label
 */

/**
 * @type {(trip: Trip[]) => PaymentTypeValue[]}
 */
export const availableValuesPaymentsByTrips = (trips) => {
  /** @type {PaymentTypeValue[]} */
  const list = [];

  const grouped = Object.groupBy(trips, trip => trip.Labels.includes(VALUES.TAG));

  if (grouped.true && grouped.true.length > 0) {
    list.push({ value: VALUES.CARD_PAYMENT_VALUE, label: VALUES.CARD_PAYMENT_LABEL });
  }

  if (grouped.false && grouped.false.length > 0) {
    list.push({ value: VALUES.ACCOUNT_VALUE, label: VALUES.ACCOUNT_LABEL });
  }

  return list;
};
