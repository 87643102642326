import React, { useEffect, useState } from 'react';
import { COMPONENTS, Select, Dialog, Button } from 'sw-ui';
import PropTypes from 'prop-types';

import AjaxButton from '../../../../components/ajaxButton';

import { TRIPBUTTONS } from '../../../../bi/constants/trips';
import { DECISIONBUTTONS } from '../../../../bi/constants/decisionButtons';

import styles from './styles.module.css';

const LABELS = {
  FUNNEL: 'Целевая воронка',
  DUPLICATE_FUNNEL: 'Дублирующая воронка',
  ACTION: 'Действие',
  STATUS: 'Статус',
  HOTELS: 'Гостиницы',
  CUSTOMERS: 'Клиенты',
  SUPPORT: 'Сопровождение',
  SAVING_SUCCESSFULLY: 'Сохранено успешно',
  ERROR: 'Произошла ошибка, попробуйте позже',
};

const SALESREPORTTYPES = [
  {
    label: 'Пропустить',
    value: 0,
    isEnable: true,
  },
  {
    label: 'Уведомить',
    value: 1,
    isEnable: true,
  },
  {
    label: 'Объединить',
    value: 2,
    isEnable: false,
  },
];

const STATUSTYPES = [
  {
    label: 'Все',
    value: 0,
  },
  {
    label: 'Активные',
    value: 1,
  },
  {
    label: 'Закрытые',
    value: 2,
  },
];

const SettingFunnels = ({ amoCRMService }) => {
  const [targetFunnel, setTargetFunnel] = useState([]);
  const [funnelSelected, setFunnelSelected] = useState(null);
  const [rules, setRules] = useState([]);
  const [loadBtnDownload, setLoadBtnDownload] = useState(false);
  const [loadBtnSave, setLoadBtnSave] = useState(false);
  const [flagSaveSuccess, setFlagSaveSuccess] = useState(false);
  const [flagError, setFlagError] = useState(false);
  const [disBtnDownload, setDisBtnDownload] = useState(true);

  const updateState = (store) => {
    const resTargetFunnel = store.targetFunnel.map(el => ({
      label: el.name,
      value: el.id,
    }));

    setTargetFunnel(resTargetFunnel);
    setRules(store.rules);
  };

  const unsubscribeAMO = () => amoCRMService.subscribe(updateState);

  useEffect(() => {
    amoCRMService.getSettingFunnelsTarget()
      .catch(() => setFlagError(true));
    unsubscribeAMO();
  }, []);

  const handleFunnelSelected = (value) => {
    setFunnelSelected(value.value);
    setDisBtnDownload(false);
  };

  const handleActionSelected = (value, id) => {
    const editIndex = rules.findIndex(el => el.duplicatePipelineId === id);
    const editRulesArray = rules.slice();

    editRulesArray[editIndex].action = value.value;
    setRules(editRulesArray);
  };

  const handleStatusSelected = (value, id) => {
    const editIndex = rules.findIndex(el => el.duplicatePipelineId === id);
    const editRulesArray = rules.slice();

    editRulesArray[editIndex].status = value.value;
    setRules(editRulesArray);
  };

  const saveDuplicateFunnels = () => {
    setLoadBtnSave(true);

    amoCRMService.getSaveSettingFunnels(rules)
    .then(() => setFlagSaveSuccess(true))
    .catch(() => setFlagError(true))
    .finally(() => setLoadBtnSave(false));
  };

  const closeSaveSuccessForm = () => {
    setFlagSaveSuccess(false);
    setFlagError(false);
  };

  const renderLoading = () => {
    if (!flagSaveSuccess) {
      return null;
    }

    return (
      <Dialog onClick={ closeSaveSuccessForm } width={ COMPONENTS.DIALOG.MIDDLE }>
        <div className={ styles.dialog }>
          <p>{ LABELS.SAVING_SUCCESSFULLY }</p>
          <div className={ `${styles.row} ${styles.right}` }>
            <Button
              label={ TRIPBUTTONS.CLOSE }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ closeSaveSuccessForm }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  const renderError = () => {
    if (!flagError) {
      return null;
    }

    return (
      <Dialog onClick={ closeSaveSuccessForm } width={ COMPONENTS.DIALOG.MIDDLE }>
        <div className={ styles.dialog }>
          <p>{ LABELS.ERROR }</p>
          <div className={ `${styles.row} ${styles.right}` }>
            <Button
              label={ TRIPBUTTONS.CLOSE }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ closeSaveSuccessForm }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  const renderFunnelNames = () => (
        rules.map(({ duplicatePipelineId, duplicatePipelineName, action, status }) => (
          <div className={ styles.wrapper_line } key={ duplicatePipelineId }>
            <div className={ styles.wrapper_header }>
              <p>{duplicatePipelineName}</p>
            </div>
            <div className={ styles.wrapper_select }>
              <Select
                items={ SALESREPORTTYPES.filter(value => value.isEnable === true) }
                value={ action }
                theme={ COMPONENTS.SELECT.THEME.LIGHT }
                onChange={ value => handleActionSelected(value, duplicatePipelineId) }
              />
            </div>
            <div className={ styles.wrapper_select }>
              <Select
                items={ STATUSTYPES }
                value={ status }
                theme={ COMPONENTS.SELECT.THEME.LIGHT }
                onChange={ value => handleStatusSelected(value, duplicatePipelineId) }
              />
            </div>
          </div>
         ))
      );

  const renderContent = () => {
    if (!rules.length) {
      return null;
    }

    return (
      <div>
        <div className={ styles.wrapper_content }>
          <div className={ styles.wrapper_line }>
            <div className={ styles.wrapper_header }>
              <p>{LABELS.DUPLICATE_FUNNEL}</p>
            </div>
            <div className={ styles.wrapper_header }>
              <p>{LABELS.ACTION}</p>
            </div>
            <div className={ styles.wrapper_header }>
              <p>{LABELS.STATUS}</p>
            </div>
          </div>
          {renderFunnelNames()}
        </div>
        <div className={ styles.button_save }>
          <AjaxButton
            label={ DECISIONBUTTONS.LABELS.SAVE }
            loading={ loadBtnSave }
            onClick={ () => saveDuplicateFunnels() }
          />
        </div>
      </div>
    );
  };

  const uploadDuplicateFunnels = (targetPipelineId) => {
    setLoadBtnDownload(true);
    amoCRMService.getSettingFunnelsDuplicate(targetPipelineId)
    .catch(() => setFlagError(true))
    .finally(() => setLoadBtnDownload(false));
    unsubscribeAMO();
  };

  return (
    <div className={ styles.wrapper }>
      <div>
        <div>{LABELS.FUNNEL}</div>
        <div className={ styles.wrapper_cap }>
          <div className={ styles.select_header }>
            <Select
              items={ targetFunnel || SALESREPORTTYPES }
              value={ funnelSelected }
              theme={ COMPONENTS.SELECT.THEME.BORDER }
              onChange={ value => handleFunnelSelected(value) }
            />
          </div>
          <div className={ styles.button }>
            <AjaxButton
              label={ DECISIONBUTTONS.LABELS.DOWNLOAD }
              loading={ loadBtnDownload }
              disabled={ disBtnDownload }
              onClick={ () => uploadDuplicateFunnels(funnelSelected) }
            />
          </div>
        </div>
      </div>
      {renderContent()}
      {renderLoading()}
      {renderError()}
    </div>
  );
};

SettingFunnels.propTypes = {
  amoCRMService: PropTypes.object.isRequired,
};

export default SettingFunnels;
