import React from 'react';
import { createRoot } from 'react-dom/client';

import UploadContacts from './components/uploadContacts';
import AmoReportPage from './components/amoReport';
import SettingFunnels from './components/settingFunnels';

const PAGENAME = {
  UPLOADING: 'uploading',
  AMOREPT: 'amorept',
  SETTING_FUNNELS: 'settingFunnels',
};

const renderComponents = (box, opts) => {
  let page = null;

  const {
    params: { amocrmId },
    amoCRMService,
  } = opts;

  switch (amocrmId) {
    case PAGENAME.UPLOADING: {
      page = (
        <UploadContacts
          amoCRMService={ amoCRMService }
        />
      );
      break;
    }
    case PAGENAME.AMOREPT: {
      page = (
        <AmoReportPage
          amoCRMService={ amoCRMService }
        />
      );
      break;
    }
    case PAGENAME.SETTING_FUNNELS: {
      page = (
        <SettingFunnels
          amoCRMService={ amoCRMService }
        />
      );
      break;
    }
  }

  if (page) {
    const root = createRoot(box);

    root.render(page);

    return root;
  }

  return null;
};

export default renderComponents;
